module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-31655526-8","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fungears","short_name":"Fungears","start_url":"/","background_color":"#C8ECFC","theme_color":"#395673","display":"standalone","icon":"assets/logo-fungears-notext.png"},
    }]
